'use client'

import type { CrossSellingClientProps } from 'cross-selling/types'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import dynamic from 'next/dynamic'
import { useRef } from 'react'

const DynamicClientCrossSelling = dynamic(() =>
	import('./DynamicClientCrossSelling').then(
		(mod) => mod.DynamicClientCrossSelling
	)
)

export const ClientCrossSelling = ({
	crossSellingItems,
	'data-testid': dataTestId,
	imageSortCriteria,
	title,
	location,
	productListId,
	className,
	hasFavoriteButton,
	variant,
	action,
}: CrossSellingClientProps) => {
	const clientCrossSellingRef = useRef(null) // Falta definir la referencia

	const productCardEntry = useIntersectionObserver(clientCrossSellingRef, {
		threshold: [0, 1],
		rootMargin: '0px 0px 150px 0px',
		freezeOnceVisible: true,
	})

	return (
		<div ref={clientCrossSellingRef}>
			{productCardEntry?.isIntersecting ? (
				<DynamicClientCrossSelling
					crossSellingItems={crossSellingItems} // Se corrigió la referencia a la variable correcta
					data-testid={dataTestId} // Se corrigió el nombre de la prop
					imageSortCriteria={imageSortCriteria}
					title={title} // Se corrigió la referencia a la prop correcta
					location={location}
					productListId={productListId} // Se corrigió para usar la prop adecuada
					className={className}
					hasFavoriteButton={hasFavoriteButton}
					variant={variant}
					action={action}
				/>
			) : null}
		</div>
	)
}
