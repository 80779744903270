'use client'
import { useSetMasterData } from 'master-data/hooks/useSetMasterData/useSetMasterData'
import { type ReactNode, useEffect } from 'react'
import type { MasterData } from 'types/masterData'

import { useMasterDataIsReady } from '../../hooks/useMasterDataIsReady/useMasterDataIsReady'

interface PageMasterDataProviderProps {
	masterData: MasterData
	autoReady?: boolean
	children: ReactNode
}

export function PageMasterDataProvider({
	masterData: pageMasterData,
	autoReady = true,
	children,
}: PageMasterDataProviderProps) {
	const { setMasterData } = useSetMasterData()
	const { setMasterDataIsReady } = useMasterDataIsReady()

	// Replace layout masterData with page masterData on first render
	useEffect(() => {
		setMasterData(pageMasterData)
		if (autoReady) {
			setMasterDataIsReady(true)
		}
	}, [autoReady, setMasterData, pageMasterData, setMasterDataIsReady])

	return children
}
